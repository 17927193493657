import $ from "jquery";
import 'jquery.easing';
import simplyScroll from 'jquery-simplyscroll';
import 'picturefill';
import 'picturefill/dist/plugins/intrinsic-dimension/pf.intrinsic.min.js';
// 遅延読み込み
import lozad from 'lozad';
const options = {
	rootMargin: '1%',
}
const observer = lozad('.lozad', options);
observer.observe();

(function ($) {
	var $win = $(window);
	var $header = $(".l-header");
	var $header21 = $(".hc-l-header");
	var mq = window.matchMedia("(max-width: 768px)");

	$(function () {
		var url = location.href;
		var host = location.host;
		var hash = location.hash;
		var path = location.pathname;

		$('body').addClass('is-loaded');
		setTimeout(function () {
			$('header').addClass('is-show');
		}, 900);
		$(".p-animations--images span").simplyScroll({
			pauseOnHover: false,
			pauseOnTouch: false,
			orientation: 'vertical',
			customClass: 'vert',
			speed: 1
		});

		if (matchMedia) {
			mq.addListener(WidthChange);
			WidthChange(mq);
		}
	});

	$win.on('scroll', function () {
		var $position = $win.scrollTop();
		if ($position > 1) {
			$header.addClass("is-move");
			$header21.addClass("is-move");
			//$(".p-animations--border").addClass("is-fix");
		} else if ($position < 1) {
			$header.removeClass("is-move");
			$header21.removeClass("is-move");
			//$(".p-animations--border").removeClass("is-fix");
		}
	});

	function fixwindow(x) {
		if (!x.hasClass("is-open")) {
			x.addClass("is-open");
			let ySctoll = window.pageYOffset;
			sessionStorage.setItem("ySctoll", ySctoll);
		} else {
			x.removeClass("is-open");
		}
	}

	function anker($hh, $bh, $target) {
		var position = $target.offset().top - $hh - $bh;
		$('body,html').stop().animate({ scrollTop: position }, 600);
	}


	$win.on('load', function () {
		setTimeout(function () {
			var headerHeight = $header.outerHeight();
			var $bh = $(".c-animations--border__top").innerHeight();
			var urlHash = location.hash;
			if (urlHash) {
				var target = $(urlHash);
				var head = headerHeight + $bh
				var position = target.offset().top - head;
				$('body,html').stop().animate({ scrollTop: position }, 600);
			}
		}, 100);

		if($('.c-section__heading--news').length){
			var newsSlide = $('.c-section__heading--news .swiper-slide').length;
			if(newsSlide < 5){
				$('.c-section__heading--news .swiper-pagination').css({
					'display':'none'
				});
			}
		}
	});

	function archiveLink(){
		if($('.p-archive-links').length){
			var $archiveHeight = $('.p-archive-links').innerHeight();
			$('.l-wrapper').css({
				'padding-top': $archiveHeight + 'px'
			});

			if($('.l-header').length){
				$('.l-header').css({
					'top': $archiveHeight + 'px'
				});
			}

			if($('.hc-l-header').length){
				$('.hc-l-header').css({
					'top': $archiveHeight + 'px'
				});
			}

			$('.c-animations--border__top,.c-animations--border__left,.c-animations--border__right').css({
				'top': $archiveHeight + 'px'
			})
			if($('.hc-p-header-navigation__toggle').length){
				$('.hc-p-header-navigation__toggle').css({
					"margin-top": $archiveHeight + 'px'
				});
			}
		}
	}


	$(document).on('click', 'a[href*="#"]', function () {
		$('is-fade').addClass('is-faeded');
		var headerHeight = $('.l-header').outerHeight();
		var $bh = $(".c-animations--border__top").innerHeight();
		var time = 600;
		var target = $(this.hash);
		if (!target.length) return;
		var head = headerHeight + $bh;

		var targetY = target.offset().top - head;
		$('html,body').animate({ scrollTop: targetY }, time, 'swing');
		return false;
	});

	$(".p-header__toggle").on("click", function () {
		fixwindow($header);
	});

	function WidthChange(mq) {
		archiveLink();
		if (!mq.matches) {
			if ($header.hasClass("is-open")) {
				$("body").css({
					position: "",
					top: "",
				});
				$("html").css({
					overflow: "",
				});

				if ($header.hasClass("is-open")) {
					$header.removeClass("is-open");
				}
			}
		} else {
		}
	}

	$(".js-hc-header-toggle").on('click',function(){
		if($(this).closest('.hc-l-header').hasClass('is-open')){
			$(this).closest('.hc-l-header').removeClass('is-open');
      $("body").css({
        position: "",
        top: "",
      });
      $("html").css({
        overflow: "",
      });
      let data = sessionStorage.getItem("ySctoll");
      scrollTo(0, data);
		}else{
			$(this).closest('.hc-l-header').addClass('is-open');
      let ySctoll = window.pageYOffset;
      sessionStorage.setItem("ySctoll", ySctoll);
      $("body").css({
        position: "fixed",
        top: -ySctoll + "px",
      });
      $("html").css({
        overflow: "hidden",
      });
		}
	});

	$(document).on("click", function (e) {
		if($(e.target).hasClass('hc-p-header-overlay')){
			$('.hc-l-header').removeClass('is-open');
      $("body").css({
        position: "",
        top: "",
      });
      $("html").css({
        overflow: "",
      });
      let data = sessionStorage.getItem("ySctoll");
      scrollTo(0, data);
		}
	});

})($);

import Swiper from 'swiper';
const carousel = document.querySelectorAll('.swiper-container');
if (carousel.length) {

	const targetElem = document.querySelectorAll('.swiper-container .swiper-slide');
	const targetCount = targetElem.length;

	if (targetCount > 1) {
		const newsOptions = {
			speed: 600,
			watchOverflow: true,
			slidesPerView: 1,

			threshold: 10,
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
		}
		const news = new Swiper(carousel, newsOptions);

	} else {
		carousel[0].classList.add('is-disabled');
		const newsOptions = {
			speed: 600,
			preloadImages: false,
			watchOverflow: true,
			slidesPerView: 1,

			threshold: 10,
			loop: false,
			navigation: false,
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
		}
		const news = new Swiper(carousel, newsOptions);
	}

}

// スクロール時のコンテンツ表示アニメーション
import inView from "in-view";
if (document.getElementsByClassName("is-fade").length) {
	inView(".is-fade")
		.on("enter", (el) => {
			el.classList.add("is-faded");
		})
}
// - メインビジュアルのヘッダー色
if (document.getElementsByClassName("main-visual").length) {
	inView(".main-visual")
		.on("enter", (el) => {
			document.getElementsByClassName("l-header")[0].classList.add("-is-white");
		}).on("exit", (el) => {
			document.getElementsByClassName("l-header")[0].classList.remove("-is-white");
		});
}
